export default [
    {
        id: '@privateCar',
        reducer: function () { return import('./privateCard.reducer'); }
    },
    {
        point: '@@components',
        namespace: '@privateCar',
        onload: function () { return [{ key: 'PrivateCardView', component: function () { return import('./private-view-card'); } }]; }
    }
];
